import React, { useState, useEffect } from 'react';

function PrivacyPolicyPage() {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/privacy-policy.html')
      .then(response => response.text())
      .then(data => setContent(data))
      .catch(error => console.error('Ошибка загрузки текста политики конфиденциальности:', error));
  }, []);

  return (
    <iframe
      title="Offer Page"
      srcDoc={content}
      style={{ width: '100%', height: '99vh', border: 'none' }}
    />
  );
}

export default PrivacyPolicyPage;
