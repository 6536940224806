

import React, { useState, useEffect } from 'react';
import s from '../styles/Subscription.module.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Subscription = () => {
  const [hasSubscription, setHasSubscription] = useState(null); // Состояние для хранения информации о подписке
  const [loading, setLoading] = useState(true); // Состояние для отображения загрузки
  const [error, setError] = useState(null); // Состояние для ошибок
  const { t } = useTranslation(); 
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null); 


  // Данные по вариантам подписки
  const subscriptions = [
    { id: 1, title: t('1 Week'), price: '249 рублей', description: t('Enjoy a one-week premium subscription with all features.'), discount: t('discount', { percent: '10%' }) },
    { id: 2, title: t('1 Month'), price: '499 рублей', description: t('Unlock all features for a full month.'), discount: t('discount', { percent: '50%' }) },
    { id: 3, title: t('6 Months'), price: '2389 рублей', description: t('Half-year of premium access at a discounted rate.'), discount: t('discount', { percent: '60%' }) },
    { id: 4, title: t('1 Year'), price: '3599 рублей', description: t('Best value: get premium access for a whole year.'), discount: t('discount', { percent: '70%' })}
  ];



  // Имитация проверки подписки (можно заменить на реальный API-запрос)
  const checkSubscription = async () => {
    try {
      setLoading(true); // Показываем индикатор загрузки
      // Здесь можно сделать API-запрос для проверки подписки пользователя
      const response = await fetch('/api/subscription', { credentials: 'include' });
      
      const data = await response.json();
      setHasSubscription(data.isActive); // Устанавливаем состояние подписки
      setSubscriptionData(data);
    } catch (err) {
      setError('Error checking subscription');
    } finally {
      setLoading(false); // Отключаем индикатор загрузки
    }
  };

  // Выполняем проверку подписки при загрузке компонента
  useEffect(() => {
    checkSubscription();
  }, []);

  const handleCardClick = (subscription) => {
    setSelectedSubscription(subscription);
  };

  // Сбрасываем выбранную подписку (возврат к списку)
  const handleBack = () => {
    setSelectedSubscription(null);
  };

  // Обработка загрузки и ошибок
  if (loading) {
    return <div>{t("Loading")}...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // // Если подписка активна, показываем основной контент
  // if (hasSubscription) {
  //   return (
  //     <div>
  //       <h1>Welcome to the premium content!</h1>
  //       <p>Enjoy all the features of your subscription.</p>
  //     </div>
  //   );
  // }

  // Если подписки нет, показываем предложение приобрести её
  return (
    <div className={s.container}>
    {/* <div>
      <h1 className={s.h1}>No Subscription</h1>
      <p>You don't have an active subscription. Please consider subscribing to access premium content.</p>
      <button onClick={() => alert('Redirecting to purchase page...')}>
        Buy Subscription
      </button>
    </div> */}
    
    {hasSubscription && subscriptionData && (
      <div className={s.hasSubscription}>
        <p>{t('Your subscription will renew')}: {new Date(subscriptionData.end_date).toLocaleDateString()}</p>
      </div>
    )}

  <div className={s.subscription_container}>
  <div className={s.back}>
  <h1>{t("Choose Your Subscription Plan")}</h1>
          <Link to="/search" className={s.btn_prev_2}>
            X
          </Link>                 
      </div>
      
      
      {/* Если выбрана конкретная подписка, показываем её подробности */}
      {selectedSubscription ? (
        <div className={s.subscription_details}>
          <h2>{selectedSubscription.title}</h2>
          <p>{selectedSubscription.description}</p>
          <p>{t("Price")}: {selectedSubscription.price}</p>
          <p className={s.discount}>{selectedSubscription.discount}</p>
          <button onClick={handleBack}>{t("Bay now")}</button>
        </div>
      ) : (
        <>
        <div className={s.VIP_div}>
            <img className={s.VIP_img2} src={`${process.env.PUBLIC_URL}/VIP.svg`} alt="VIP" />
        </div>  
        
        <div className={s.subscription_grid}>
          
          {subscriptions.map((subscription) => (
            <div
              key={subscription.id}
              className={s.subscription_card}
              onClick={() => handleCardClick(subscription)}
            >
              <h3>{subscription.title}</h3>
              <p className={s.price_card}>{subscription.price}</p>
              <p className={s.discount}>{subscription.discount}</p>

            </div>
          ))}
        </div>
        </>
      )}

      {/* Описание преимуществ подписки */}
      <div className={s.subscription_benefits}>
      <h2>{t('Why Upgrade to Premium?')}</h2>
        <ul>
          <li>{t('Access to unlimited likes')}</li>
          <li>{t('Send unlimited messages')}</li>
          <li>{t('See who liked your profile')}</li>
          <li>{t('Get priority support')}</li>
          <li>{t('Boost your profile visibility')}</li>
        </ul>
      </div>
    </div>
    </div>
  );
};

export default Subscription;
