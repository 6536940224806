export function getCroppedImg(imageSrc, croppedAreaPixels) {
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.setAttribute('crossOrigin', 'anonymous'); // Решает проблемы с CORS
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
    });

  return new Promise(async (resolve, reject) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Устанавливаем размер холста для исходного обрезанного изображения
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    // Рисуем обрезанное изображение на канвас
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    // Теперь создаем новый канвас для масштабированного изображения 
    const outputCanvas = document.createElement('canvas');
    const outputCtx = outputCanvas.getContext('2d');

    // Устанавливаем фиксированный размер 640x852
    outputCanvas.width = 640;
    outputCanvas.height = 852;

    // Масштабируем исходное обрезанное изображение на новый канвас
    outputCtx.drawImage(canvas, 0, 0, 640, 852);

    // Преобразуем канвас в blob с качеством JPEG
    outputCanvas.toBlob((blob) => {
      if (!blob) {
        console.error('Canvas is empty');
        return reject(new Error('Canvas is empty'));
      }
      const fileUrl = URL.createObjectURL(blob); // URL для просмотра изображения
      resolve({ blob, fileUrl });
    }, 'image/jpeg');
  });
}
