import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  }
};

i18n
  .use(initReactI18next) // Передаем i18n в react-i18next
  .init({
    resources,
    lng: 'ru', // Устанавливаем язык по умолчанию
    fallbackLng: 'en', // Язык по умолчанию, если перевод не найден
    interpolation: {
      escapeValue: false // React уже обрабатывает экранирование
    }
  });

export default i18n;
