import React, { useEffect, useState } from 'react';

function OfferPage() {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/offer.html')
      .then(response => response.text())
      .then(data => setContent(data))
      .catch(error => console.error('Ошибка загрузки текста оферты:', error));
  }, []);

  return (
    <iframe
      title="Offer Page"
      srcDoc={content}
      style={{ width: '100%', height: '99vh', border: 'none'}}
    />
  );
}

export default OfferPage;
