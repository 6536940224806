import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext'; // Подключаем AuthContext для автоматического входа
import s from '../styles/Register.module.css';
import { useTranslation } from 'react-i18next';

function Register() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    csrf_token: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { fetchCurrentUser } = useContext(AuthContext); // Получаем функцию обновления пользователя
  const { t } = useTranslation();
  const [isAgreed, setIsAgreed] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;



  const handleGoogleLogin = useCallback(async (response) => {
    try {
      const { credential } = response;
      const googleResponse = await fetch('/api/googlelogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_token: credential }), // Отправляем id_token от Google на сервер
      });

      if (!googleResponse.ok) {
        const data = await googleResponse.json();
        setError(data.error || 'Google login failed');
        return;
      }

      // Успешный вход через Google
      const data = await googleResponse.json();
      console.log('Google login successful:', data);

      // Обновляем данные текущего пользователя после входа через Google
      await fetchCurrentUser();

      // Перенаправляем пользователя
      navigate('/search');
    } catch (err) {
      setError('Error logging in with Google');
    }
  }, [fetchCurrentUser, navigate]);

  useEffect(() => {
    /* Загружаем Google Identity Services при монтировании компонента */
    window.google.accounts.id.initialize({
      client_id: '322100129717-tkkg9ak1aljhk4ji4jr310f27vgr8o4u.apps.googleusercontent.com', // Замените на ваш клиентский ID
      callback: handleGoogleLogin,
    });

    window.google.accounts.id.renderButton(
      document.getElementById('googleSignInDiv'),
      { theme: 'outline', size: 'large' }
    );
  }, [handleGoogleLogin]);

  useEffect(() => {
    // Получаем CSRF токен с сервера
    fetch('/api/csrf_token')
      .then((response) => response.json())
      .then((data) => setFormData((prevFormData) => ({ ...prevFormData, csrf_token: data.csrf_token })));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
      if (!isAgreed) {
        e.preventDefault();  // Предотвращаем отправку формы, если галочка не установлена
        showAgreementHint();
        return;
      }
      if (!passwordPattern.test(formData.password)) {
        e.preventDefault();
        setError(t("Password must contain at least 8 characters, including a letter, a number."));
        return;
      }
    e.preventDefault();  // Предотвращаем перезагрузку страницы

    try {
      // Отправляем запрос на регистрацию
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const result = await response.json();

      if (response.ok) {
        setSuccess(true);
        // Если регистрация успешна, выполняем автоматический вход
        const loginResponse = await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: formData.email, password: formData.password }),
        });

        if (loginResponse.ok) {
          // Если автологин успешен, обновляем текущего пользователя
          await fetchCurrentUser();
          // Перенаправляем пользователя в его профиль
          navigate('/search');
        } else {
          setError(t("Registration succeeded, but login failed. Please log in manually."));
        }
      } else {
        setError(t(result.error) || result.error);
      }
    } catch (err) {
      setError(t("An error occurred"));
    }
  };

  const showAgreementHint = () => {
    setShowHint(true);
    setTimeout(() => setShowHint(false), 3000); // Скрываем подсказку через 3 секунды
  };

  return (
    <div>
  <h1 className='all_str_h1'>{t("Register")}</h1>
  <div className={s.login_container}>
    <div className={s.login_form}>
      {showHint && <div className={s.hint}>{t("Please agree to the Privacy Policy to continue")}</div>}
      {error && <div className={s.error}>{error}</div>}
      {success ? (
        <div>{t("Registration successful")}!</div>
      ) : (
        <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder={t("Email")} // Добавлен placeholder
              autoComplete="email"
            />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder={t("Password")}  // Добавлен placeholder
              autoComplete="new-password"
            />
            <div className={s.checkbox_container}>
                <input
                  type="checkbox"
                  id="privacyPolicy"
                  checked={isAgreed}
                  onChange={() => setIsAgreed(!isAgreed)}
                />
                <label htmlFor="privacyPolicy">
                  {t("I agree with the")} <a href="/privacy-policy" target="_blank">{t("Privacy Policy")}</a>
                </label>
              </div>
              <button type="submit" onClick={(e) => !isAgreed && (e.preventDefault(), showAgreementHint())}>
                {t("Register_start")}
              </button>
        </form>
      )}
      {/* Кнопка входа через Google */}
      {/* <div id="googleSignInDiv" className={s.google_sign_in}></div> */}
      <div className={s.google_container}>
            <div id="googleSignInDiv" className={s.google_sign_in}></div>
            {!isAgreed && (
              <div className={s.overlay} onClick={showAgreementHint}></div> // Оверлей, блокирующий доступ к кнопке
            )}
          </div>
          
    </div>
    
  </div>
</div>

  );
}

export default Register;
