import React from 'react';

const MatchesIcon = () => (
  <svg
    width="1.1em"
    height="1.1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-8.40422, -9.49745)">
      <path
        style={{
          strokeWidth: 0.087669,
          strokeLinecap: 'square',
          strokeLinejoin: 'round',
          strokeDashoffset: 489.052,
          paintOrder: 'stroke markers fill',
          stroke: 'currentColor', // Используем currentColor для цвета обводки
          fill: 'currentColor',   // Используем currentColor для цвета заливки
          
        }}
        d="m 17.610521,19.354553 a 2.2264006,2.1504097 0 0 1 -2.192547,2.150161 2.2264006,2.1504097 0 0 1 -2.259225,-2.084772 2.2264006,2.1504097 0 0 1 2.123841,-2.213561 2.2264006,2.1504097 0 0 1 2.323813,2.017455 m 5.671237,-0.353528 a 2.9483004,2.9247141 0 0 1 -2.90347,2.924376 2.9483004,2.9247141 0 0 1 -2.991767,-2.835443 2.9483004,2.9247141 0 0 1 2.812487,-3.010604 2.9483004,2.9247141 0 0 1 3.077298,2.743886 m -7.77058,3.310696 c -3.404377,0.385375 -5.270088,5.167252 -5.217444,7.970604 0.335649,-0.0013 2.685175,0.0164 3.305935,0.0084 0.152425,-0.002 0.07253,-0.107839 0.07812,-0.172177 0.405778,-4.667233 2.150556,-5.799459 3.692556,-6.936246 -0.224322,-0.197141 -0.572892,-0.525671 -0.803111,-0.655515 -0.362072,-0.205437 -0.714996,-0.214146 -1.056056,-0.215059 z m 11.048313,7.991862 a 6.1964345,7.4521303 0 0 0 -3.079077,-6.511071 6.1964345,7.4521303 0 0 0 -6.228283,-0.0041 6.1964345,7.4521303 0 0 0 -3.085015,6.507005"
      />
    </g>
  </svg>
);

export default MatchesIcon;
