
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import s from '../styles/Profile.module.css'; // Подключение стилей

function Profile() {
  const [profile, setProfile] = useState(null);  // Состояние для хранения данных профиля
  const [loading, setLoading] = useState(true);  // Состояние загрузки
  const [error, setError] = useState(null);  // Состояние ошибки
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);  // Текущий индекс фото для отображения
  const navigate = useNavigate();



  // Обработчик свайпов для смены фото
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextPhoto(),  // Свайп влево - следующее фото
    onSwipedRight: () => handlePreviousPhoto(),  // Свайп вправо - предыдущее фото
    onSwipedDown: () => navigate('/search'),  // Свайп вниз возвращает на Matches
    preventScrollOnSwipe: true,
    delta: 10,  // Минимальное расстояние для распознавания свайпа
  });



  // Функция получения профиля текущего пользователя
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('/api/profile', {
          method: 'GET',
          credentials: 'include', // Включаем куки для сессии
        });

        if (response.ok) {
          const data = await response.json();
          setProfile(data);
        } else {
          if (response.status === 404) {
            navigate('/CreateProfile');  // Перенаправляем, если профиль не найден
          } else {
            console.error('Error fetching profile');
            setError('Error fetching profile');
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Error fetching profile');
      } finally {
        setLoading(false);  // Завершаем загрузку
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleNextPhoto = () => {
    if (profile && profile.photos) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === profile.photos.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handlePreviousPhoto = () => {
    if (profile && profile.photos) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === 0 ? profile.photos.length - 1 : prevIndex - 1
      );
    }
  };

  if (loading) {
    return <div className='loading'>Loading...</div>;
  }

  if (!profile) {
    return <div>Profile not found</div>;
  }

  return (
    <div>
    <h1 className='all_str_h1'>My Profile</h1>
    <div className={s.profile_container}>
      <div className={s.profile}>
        <div {...handlers} className={s.img}>
          {/* Отображаем текущее фото */}
          <img
            src={`/api/uploads/${profile.photos[currentPhotoIndex]}`}
            alt="Profile"
            className={s.styled_image}
          />
           {profile.photos.length > 1 ? (
            <div>
          {/* Значки для перелистывания фото */}
          <button className={s.prev} onClick={handlePreviousPhoto}>{'<'}</button>
          <button className={s.next} onClick={handleNextPhoto}>{'>'}</button>
          </div>) : null}
        </div>
        {profile.photos.length > 1 ? (
        <div className={s.indicators}>
          {/* Отображаем индикаторы */}
          {profile.photos.map((photo, index) => (
            <span
              key={index}
              className={`dot ${index === currentPhotoIndex ? 'active_dot' : ''}`}
            ></span>
          ))}
        </div>) : null}
        <div className={s.profile_bio}>
          <div className={s.name_age}>
            <p>{profile.name}</p>
            <p>{profile.age}</p>
          </div>
          <p>City: {profile.city}</p>
          <p>{profile.gender}</p>
        </div>
      </div>
      <div className={s.bio_card}>
        <p>{profile.bio}</p> {/* Bio выводится в отдельной карточке */}
      </div>
      <div className={s.btn_profile}>
        <button onClick={() => navigate('/edit_profile')}>Edit</button>
      </div>
      {error && <p>{error}</p>} {/* Отображаем ошибку, если есть */}
    </div>
    </div>
  );
}

export default Profile;
