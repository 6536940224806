// import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';


// const TelegramWebApp = () => {
//   const [userId, setUserId] = useState(null);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [token, setToken] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     try {
//       console.log("Инициализация Web App...");

//       // Проверяем, что мы находимся в окружении Telegram
//       if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
//         console.log("Telegram Web App API найден.");
//         setErrorMessage('Telegram Web App API найден.');
        
//         window.Telegram.WebApp.ready();

//         const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
        
//         if (initDataUnsafe && initDataUnsafe.user) {
//           setUserId(initDataUnsafe.user.id);
//           console.log(`User ID: ${initDataUnsafe.user.id}`);
//           setErrorMessage(`User ID: ${initDataUnsafe.user.id}`);

//           // Автоматически отправляем запрос на сервер для получения JWT
//           authenticateTelegramUser(initDataUnsafe);
//         } else {
//           console.log("Данные о пользователе не найдены.");
//           setErrorMessage("Данные о пользователе не найдены.");
//         }
//       } else {
//         console.error("Telegram Web App API не найден. Откройте приложение через Telegram.");
//         setErrorMessage("Telegram Web App API не найден. Откройте приложение через Telegram.");
//       }
//     } catch (error) {
//       console.error("Ошибка при инициализации Web App:", error);
//       setErrorMessage(`Ошибка: ${error.message}`);
//     }
//   }, []);

//   // Функция для отправки запроса на получение JWT
//   const authenticateTelegramUser = async (initDataUnsafe) => {
//     try {
//       const initData = window.Telegram.WebApp.initData;  // Инициализационные данные Telegram
//       console.log('initData:', initData); 
//       const response = await fetch('/api/auth/telegram', {
//         method: 'POST',
//         headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ initData }),  // Передаем initData в теле запроса
//         });
    

//       if (response.ok) {
//         const data = await response.json();
//         const jwtToken = data.token;

//         // Сохраняем JWT в localStorage
//         localStorage.setItem('jwtToken', jwtToken);
//         setToken(jwtToken);
//         console.log('JWT token сохранен в localStorage');
//       } else {
//         const errorData = await response.json();
//         console.error('Ошибка авторизации:', errorData.error);
//         setErrorMessage('Ошибка авторизации: ' + errorData.error);
//       }
//     } catch (error) {
//       console.error('Ошибка запроса:', error);
//       setErrorMessage(`Ошибка запроса: ${error.message}`);
//     }
//   };

//   // Переход на страницу /training
//   const goToTraining = () => {
//     navigate('/training');
//   };

//   return (
//     <div>
//       <h1>Telegram Web App</h1>
//       {userId ? (
//         <div>
//           <p>Ваш user_id: {userId}</p>
//           {token ? (
//             <button onClick={goToTraining}>Перейти к тренировке</button>
//           ) : (
//             <p>Получение токена...</p>
//           )}
//         </div>
//       ) : (
//         <p>{errorMessage || 'Загрузка данных пользователя...'}</p>
//       )}
//     </div>
//   );
// };

// export default TelegramWebApp;


// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../AuthContext';

// const TelegramWebApp = () => {
//   const [userId, setUserId] = useState(null);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [token, setToken] = useState(null);
//   const navigate = useNavigate();
//   const { fetchTelegramtUser } = useContext(AuthContext); 

//   useEffect(() => {
//     const authenticateTelegramUser = async (initDataUnsafe) => {
//       try {
//         const initData = window.Telegram.WebApp.initData;  // Инициализационные данные Telegram
//         console.log('initData:', initData); 
//         const response = await fetch('/api/auth/telegram', {
//           method: 'POST',
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ initData }),  // Передаем initData в теле запроса
//         });

//         if (response.ok) {
//           const data = await response.json();
//           const jwtToken = data.token;

//           // Сохраняем JWT в localStorage
//           localStorage.setItem('jwtToken', jwtToken);
//           setToken(jwtToken);
//           await fetchTelegramtUser();
//           console.log('JWT token сохранен в localStorage');
//         } else {
//           const errorData = await response.json();
//           console.error('Ошибка авторизации:', errorData.error);
//           setErrorMessage('Ошибка авторизации: ' + errorData.error);
//         }
//       } catch (error) {
//         console.error('Ошибка запроса:', error);
//         setErrorMessage(`Ошибка запроса: ${error.message}`);
//       }
//     };

//     try {
//       console.log("Инициализация Web App...");

//       // Проверяем, что мы находимся в окружении Telegram
//       if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
//         console.log("Telegram Web App API найден.");
//         setErrorMessage('Telegram Web App API найден.');
        
//         window.Telegram.WebApp.ready();

//         const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
        
//         if (initDataUnsafe && initDataUnsafe.user) {
//           setUserId(initDataUnsafe.user.id);
//           console.log(`User ID: ${initDataUnsafe.user.id}`);
//           setErrorMessage(`User ID: ${initDataUnsafe.user.id}`);

//           // Автоматически отправляем запрос на сервер для получения JWT
//           authenticateTelegramUser(initDataUnsafe);
//         } else {
//           console.log("Данные о пользователе не найдены.");
//           setErrorMessage("Данные о пользователе не найдены.");
//         }
//       } else {
//         console.error("Telegram Web App API не найден. Откройте приложение через Telegram.");
//         setErrorMessage("Telegram Web App API не найден. Откройте приложение через Telegram.");
//       }
//     } catch (error) {
//       console.error("Ошибка при инициализации Web App:", error);
//       setErrorMessage(`Ошибка: ${error.message}`);
//     }
//   }, [fetchTelegramtUser]); // Добавляем fetchTelegramtUser в зависимости, если ESLint выдаёт предупреждение

//   // Переход на страницу /training
//   const goToTraining = () => {
//     navigate('/training');
//   };

//   return (
//     <div>
//       <h1>Telegram Web App</h1>
//       {userId ? (
//         <div>
//           <p>Ваш user_id: {userId}</p>
//           {token ? (
//             <button onClick={goToTraining}>Перейти к тренировке</button>
//           ) : (
//             <p>Получение токена...</p>
//           )}
//         </div>
//       ) : (
//         <p>{errorMessage || 'Загрузка данных пользователя...'}</p>
//       )}
//     </div>
//   );
// };

// export default TelegramWebApp;



import React, { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

const TelegramWebApp = () => {
  const navigate = useNavigate();
  const { authenticateTelegramUser, token } = useContext(AuthContext); 

  useEffect(() => {
    // Вызываем функцию аутентификации из контекста
    authenticateTelegramUser();
  }, [authenticateTelegramUser]);

  // Переход на страницу /training
  const goToTraining = () => {
    navigate('/training');
  };


  // Navigate to /search after receiving the token
  useEffect(() => {
    if (token) {
      navigate('/search');
    }
  }, [token, navigate]);

  return (
    <div>
      <h1>Telegram Web App</h1>
      {token ? (
        <button onClick={goToTraining}>.</button>
      ) : (
        <p>Получение токена...</p>
      )}
    </div>
  );
};

export default TelegramWebApp;


