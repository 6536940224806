
import { useEffect, useContext } from 'react';  // Объединяем импорты
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

function Logout() {
  const navigate = useNavigate();
  const { fetchCurrentUser, setCurrentUser } = useContext(AuthContext); // Получаем функцию обновления пользователя

  useEffect(() => {
    const performLogout = async () => {
      try {
        const response = await fetch('/api/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',  // Включаем куки для сессии
        });


        if (response.ok) {
            setCurrentUser(null);
            await fetchCurrentUser(); // Обновляем данные текущего пользователя
          // Логаут успешен, перенаправляем на главную страницу
          navigate('/');
        } else {
          console.error('Logout failed');
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    performLogout();
  }, [fetchCurrentUser, setCurrentUser, navigate]);  // Указываем все зависимости в массиве

  return null;  // Компонент ничего не рендерит
}

export default Logout;
