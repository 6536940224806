import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App';
// import * as serviceWorker from './serviceWorker'; // Импортируем service worker

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
// Переопределяем console.log для отправки логов на сервер
// Оригинальный console.log для вывода в консоль
// const originalConsoleLog = console.log;

// // Функция для отправки логов на сервер
// const sendLogToServer = (message) => {
//   fetch('/api/logs', {  // Замените на ваш API URL
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ log: message }),
//   })
//   .catch(error => originalConsoleLog('Error sending log:', error));  // Логируем ошибки только в оригинальную консоль
// };

// // Переопределяем console.log для отправки логов на сервер
// console.log = (...args) => {
//   originalConsoleLog(...args);  // Выводим лог в консоль
//   sendLogToServer(args.join(' '));  // Отправляем лог на сервер
// };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// // Зарегистрируем service worker для PWA
// serviceWorker.register();