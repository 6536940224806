import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext'; // Пример подключения контекста авторизации
import s from '../styles/Menu.module.css';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import CustomRangeSlider from './CustomRangeSlider';
import GenderSelectCard from './GenderSelectCard';
import LanguageSelectCard from './LanguageSelectCard';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';


function SettingsPage() {
  const { t } = useTranslation(); // t используется для получения перевода

  const { token, currentUser } = useContext(AuthContext); // Получаем текущее состояние пользователя
  const [darkMode, setDarkMode] = useState(false); // Управление темой
  const [notificationsEnabled, setNotificationsEnabled] = useState(true); // Пример настройки уведомлений
  const [privacyMode, setPrivacyMode] = useState(false); // Пример настройки приватности
  const [showContactInfo, setShowContactInfo] = useState(false);

  const [profile, setProfile] = useState(null);  // Состояние для хранения данных профиля
  const [isProfileExpanded, setIsProfileExpanded] = useState(false); // Состояние для раскрытия профиля
  const [error, setError] = useState(null);  // Состояние ошибки
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);  // Текущий индекс фото для отображения
  const navigate = useNavigate();

  const [ageRange, setAgeRange] = useState([18, 100]);

  const [hasSubscription, setHasSubscription] = useState(null);
   
  const subscriptions = [
    { id: 1, title: t('1 Week'), price: '249 рублей', description: t('Enjoy a one-week premium subscription with all features.'), discount: t('discount', { percent: '10%' }) },
    { id: 2, title: t('1 Month'), price: '499 рублей', description: t('Unlock all features for a full month.'), discount: t('discount', { percent: '50%' }) },
    { id: 3, title: t('6 Months'), price: '2389 рублей', description: t('Half-year of premium access at a discounted rate.'), discount: t('discount', { percent: '60%' }) },
  
  ]
  // Чтение диапазона из localStorage при загрузке компонента
  useEffect(() => {
    const savedRange = localStorage.getItem('ageRange');
    if (savedRange) {
      // Преобразуем строку обратно в массив чисел
      setAgeRange(JSON.parse(savedRange));
    }
  }, []);

  // Сохранение диапазона в localStorage при изменении диапазона
  useEffect(() => {
    localStorage.setItem('ageRange', JSON.stringify(ageRange));
  }, [ageRange]);

  

  // Обработчик свайпов для смены фото
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextPhoto(),  // Свайп влево - следующее фото
    onSwipedRight: () => handlePreviousPhoto(),  // Свайп вправо - предыдущее фото
    // onSwipedDown: () => navigate('/search'),  // Свайп вниз возвращает на Matches
    preventScrollOnSwipe: true,
    delta: 10,  // Минимальное расстояние для распознавания свайпа
    trackTouch: true, // Включаем отслеживание touch событий
    passive: true, // Добавляем параметр passive: true для улучшения производительности
  });

  // Функция получения профиля текущего пользователя
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Проверяем, есть ли данные initData в localStorage (для пользователей из Telegram)
        const token = localStorage.getItem("jwtToken"); 
        console.log('токен:',token)
        let options = {
          method: 'GET',  // Метод указывается всегда
        };

        // Если есть initData (пользователь из Telegram), добавляем его в заголовки
        if (token) {
          options.headers = {
            "Content-Type": "application/json",
            "Authorization": token,  // Передаем JWT в заголовке
          };
        } else {
          // Если пользователь не из Telegram, включаем поддержку сессий через куки
          options.credentials = 'include';
        }

        const response = await fetch('/api/profile', options);



        // const response = await fetch('/api/profile', {
        //   method: 'GET',
        //   credentials: 'include', // Включаем куки для сессии
        // });

        if (response.ok) {
          const data = await response.json();
          setProfile(data);
          setHasSubscription(data.isActive);
        } else {
          if (response.status === 404) {
            navigate('/CreateProfile');  // Перенаправляем, если профиль не найден
          } else {
            console.error('Error fetching profile');
            setError('Error fetching profile');
            
          }
        }
      } catch (error) {
        console.error('Error:', error);
      
      } 
      // finally {
      //   setLoading(false);  // Завершаем загрузку
      // }
    };

    fetchProfile();
  }, [navigate]);


 // Прочитать тему из localStorage при первой загрузке приложения
 useEffect(() => {
  const savedTheme = localStorage.getItem('darkMode');
  if (savedTheme === 'true') {
    setDarkMode(true);
    document.documentElement.classList.add('dark_mode');
  }
}, []);

  // Функция для переключения темы
  const toggleTheme = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode);
      if (newMode) {
        document.documentElement.classList.add('dark_mode');
      } else {
        document.documentElement.classList.remove('dark_mode');
      }
      return newMode;
    });
  };


  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled); // Включение/отключение уведомлений
  };

  const togglePrivacyMode = () => {
    setPrivacyMode(!privacyMode); // Включение/отключение приватного режима
  };

  const handleNextPhoto = () => {
    if (profile && profile.photos) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === profile.photos.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handlePreviousPhoto = () => {
    if (profile && profile.photos) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === 0 ? profile.photos.length - 1 : prevIndex - 1
      );
    }
  };

  // Функция обработки клика на карточку профиля
  const handleProfile = () => {
      setIsProfileExpanded(true);  // Передаем правильный ID профиля
  };
  // Функция обработки клика на карточку профиля
  const handleBackClick = () => {
    setIsProfileExpanded(false);  // Передаем правильный ID профиля
};

const getCurrentPhoto = () => {
  if (profile && profile.photos && profile.photos.length > 0 && profile.photos[0] !== "") {
      return `/api/uploads/${profile.photos[currentPhotoIndex]}`;
  }
  return '/api/uploads/default.jpg'; // Путь к default.jpg
};

const getCurrentPhoto2 = () => {
  if (profile && profile.photos && profile.photos.length > 0 && profile.photos[0] !== "") {
      return `/api/uploads/${profile.photos[0]}`;
  }
  return '/api/uploads/default.jpg'; // Путь к default.jpg
};

// Функция для переключения отображения контактной информации
const toggleContactInfo = () => {
  setShowContactInfo((prev) => !prev);
};

  return (
    <div className={s.prof_div}>
      {isProfileExpanded && (
        <>
    <h1 className='all_str_h1'>{t('My Profile')}</h1>
    <div className={s.back2}>
          <div className={s.btn_prev_2} onClick={handleBackClick}>
          X
        </div>                 
      </div>
    
    <div className={s.profile_container}>
      <div className={s.profile}>
        <div {...handlers} className={s.img}>
          {/* Отображаем текущее фото */}
          <img
            // src={`/api/uploads/${profile.photos[currentPhotoIndex]}`}
            src={getCurrentPhoto()}
            alt="Profile"
            className={s.styled_image}
          />
           {profile.photos.length > 1 ? (
            <div>
          {/* Значки для перелистывания фото */}
          <button className={s.prev} onClick={handlePreviousPhoto}>{'<'}</button>
          <button className={s.next} onClick={handleNextPhoto}>{'>'}</button>
          </div>) : null}
        </div>
        {profile.photos.length > 1 ? (
        <div className={s.indicators}>
          {/* Отображаем индикаторы */}
          {profile.photos.map((photo, index) => (
            <span
              key={index}
              className={`dot ${index === currentPhotoIndex ? 'active_dot' : ''}`}
            ></span>
          ))}
        </div>) : null}
        <div className={s.profile_bio}>
          <div className={s.name_age}>
            <p>{profile.name}</p>
            <p>{profile.age}</p>
          </div>
          <p>City: {profile.city}</p>
          <p>{t('gender.' + profile.gender)}</p>
        </div>
      </div>
      <div className={s.bio_card}>
        <p>{profile.bio}</p> {/* Bio выводится в отдельной карточке */}
      </div>
      <div className={s.btn_profile}>
        <button onClick={() => navigate('/edit_profile')}>{t("Edit")}</button>
      </div>
      {error && <p>{error}</p>} {/* Отображаем ошибку, если есть */}
    </div>
    </>
      )}
    


    {!isProfileExpanded && (
      <>
      <h1 className='all_str_h1'>{t('Settings')}</h1>
    
    <div className={`${s.settings_page} ${darkMode ? s.dark : s.light}`}>
      <header className={s.settings_header}>
        
      </header>
      <div className={s.back}>
          <Link to="/search" className={s.btn_prev_2}>
            X
          </Link>                 
      </div>
      <div className={s.profile_div}>
            {(currentUser || token) && profile ? (
              <>
                <div className={s.profile_2}>
                  <img
                    // src={`/api/uploads/${profile.photos[0]}`}
                    src={getCurrentPhoto2()}
                    className={s.profile_photo_2}
                    alt="Profile"
                    onClick={handleProfile}
                  />
                </div>
                <div className={s.name_age_2}>
                  <p>{profile.name}</p>
                  <p>{profile.age}</p>
                </div>
              </>
            ) : (
              <p>{t("Loading profile...")}</p> // Или другое сообщение, пока профиль загружается
            )}
          </div>
          {/* <div className={s.settings_options_div_subscribe}>     
            {hasSubscription&&(
                <div className={s.hasSubscription}>
                <p>{t('Your subscription ends on')}: {new Date(profile.end_date).toLocaleDateString()}</p>
                <Link to="/subscription" className={s.add_subscribe}>
           Продлить?
          </Link>
              </div>
            )}
            {!hasSubscription&&(
                <p>подписка</p>
            )}
            </div>                          */}
            <Link to="/subscription" className={s.settings_options_div_subscribe}>
              {hasSubscription ? (
                <div className={s.hasSubscription}>
                  <p>{t('Your subscription will renew')}: {new Date(profile.end_date).toLocaleDateString()}</p>
                  {/* <span className={s.add_subscribe}>Продлить?</span> */}
                </div>
              ) : (
                <>
                <div className={s.text_podpiska}>
                 <p>{t('Get access to premium features!')}</p>
                 </div>
                 <div className={s.subscription_benefits}>
                 <ul>
                    <li>{t('Access to unlimited likes')}</li>
                    <li>{t('Send unlimited messages')}</li>
                    <li>{t('See who liked your profile')}</li>
                    <li>{t('Boost your profile visibility')}</li>
                  </ul> 
                </div>
                <div className={s.grid_map}>
                  {subscriptions.map((subscription) => (
            <div
              key={subscription.id}
              className={s.subscription_card}
            >
              <h3>{subscription.title}</h3>
              <p className={s.price_card}>{subscription.price}</p>
              <p className={s.discount}>{subscription.discount}</p>

            </div>
          ))}
            </div>
                </>
              )}
            </Link>

          <div className={s.settings_options_div}>
        <h2>
        {t("Filters")}
        </h2>
        <div><GenderSelectCard/>
        </div>
        <div className={s.slider_div}>{t("Age Range:")} {ageRange[0]} - {ageRange[1]}</div>
        <CustomRangeSlider
          min={18}
          max={100}
          step={4}
          onChange={(newRange) => setAgeRange(newRange)}
        />
      
      </div>
        <div className={s.settings_options_div}>
      <section className={s.settings_options}>
        <h2>{t("User Settings")}</h2>
        {/* Управление темой */}
        {/* <div className={s.setting_item}>
          <label htmlFor="theme-toggle">{t("Dark Mode")}</label>
          <input
            type="checkbox"
            id="theme_toggle"
            checked={darkMode}
            onChange={toggleTheme}
          />
        </div> */}
        <div className={s.setting_item}>
          <label htmlFor="theme-toggle">{t("Dark Mode")}</label>
          <label className={s.switch}>
            <input
              type="checkbox"
              id="theme-toggle"
              checked={darkMode}
              onChange={toggleTheme}
            />
            <span className={s.slider}></span>
          </label>
        </div>


        {/* Управление уведомлениями */}
        <div className={s.setting_item}>
          <label htmlFor="notifications-toggle">{t("Enable Notifications")}</label>
          <label className={s.switch}>
          <input
            type="checkbox"
            id="notifications-toggle"
            checked={notificationsEnabled}
            onChange={toggleNotifications}
          />
            <span className={s.slider}></span>
            </label>
        </div>

        {/* Управление приватным режимом */}
        <div className={s.setting_item}>
          <label htmlFor="privacy-toggle">{t("Private Mode")}</label>
          <label className={s.switch}>
          <input
            type="checkbox"
            id="privacy-toggle"
            checked={privacyMode}
            onChange={togglePrivacyMode}
          />
          <span className={s.slider}></span>
          </label>
        </div>
        <div><LanguageSelectCard/>
        </div>
        
      </section>
      
      
      </div>
      
      <div className={s.settings_options_div}>
      <section className={s.settings_menu}>
        <ul>
          {currentUser ? (
            <>
              <li>
                <Link to="/profile">{t("Profile")}</Link>
              </li>
              
              <li>
                <Link to="/training">{t("Training")}</Link>
              </li>
              <li>
              <Link to="/logout">{t("Logout")}</Link>
              </li>
            </>
            ) : token ?(
              <>
              <li>
                <Link to="/training">{t("Training")}</Link>
              </li>
            </>
          ) : (
            <>
              {/* Отображаем меню для неавторизованного пользователя */}
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/register">Register</Link>
              </li>
              
            </>
          )}
          <li>
            {/* Кнопка для отображения контактной информации */}
            <button onClick={toggleContactInfo} className={s.contactButton}>
              {t("Contact Us")}
            </button>
          </li>
        </ul>
        {showContactInfo && (
          <div className={s.requizits}>
          <div className={s.contactInfo}>
        
        
        <div className={s.contactItem}>
          <FontAwesomeIcon icon={faEnvelope} className={s.icon} />
          <a href="mailto:support@lovesekrets.ru" className={s.link}>support@lovesekrets.ru</a>
        </div>
        
        <div className={s.contactItem}>
          <FontAwesomeIcon icon={faPaperPlane} className={s.icon} />
          <a href="https://t.me/lovesekrets_support" target="_blank" rel="noopener noreferrer" className={s.link}>@lovesekrets_support</a>
        </div>
      </div>
          <div className={s.ip}>
            <p>
              ИП Хабибуллина Альфия Саррачевна
            </p>
            <p>
              ОГРНИП: 3241169000228591
            </p>
          </div>
            <div className={s.links}>
                  <div className={s.footer_links}>
              <Link to="/offer" className={s.footer_link}>
                {t('offer')}
              </Link>
              <span className={s.separator}>|</span>
              <Link to="/privacy-policy" className={s.footer_link}>
              {t('privacy-policy2')}
              </Link>
            </div>
  
            </div>
            
        </div>
        )}
      </section>
      </div>
    </div>
    
    </>
        )}
  </div>
  );
}

export default SettingsPage;





