

import React, { useState, useRef, useEffect } from 'react';
import '../styles/SliderStyles.css';


const CustomRangeSlider = ({ min = 0, max = 100, step = 1, onChange }) => {
  const sliderRef = useRef(null);
  const thumbMinRef = useRef(null);
  const thumbMaxRef = useRef(null);

  // Инициализация диапазона из localStorage или начальное значение
  const initialRange = () => {
    const savedRange = localStorage.getItem('ageRange');
    return savedRange ? JSON.parse(savedRange) : [min, max];
  };

  // Локальное состояние для диапазона
  const [range, setRange] = useState(initialRange);

  // Эффект для сохранения диапазона в localStorage при изменении
  useEffect(() => {
    localStorage.setItem('ageRange', JSON.stringify(range));
    onChange && onChange(range);
  }, [range, onChange]);

  // Функция для перемещения ползунков
  const handleMove = (clientX, index) => {
    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();
    const newValue = Math.round(
      ((clientX - rect.left) / rect.width) * (max - min) + min
    );

    setRange((prevRange) => {
      const newRange = [...prevRange];
      newRange[index] = Math.min(Math.max(newValue, min), max);
      
      // Предотвращаем пересечение ползунков
      if (index === 0 && newRange[0] >= newRange[1]) {
        newRange[0] = newRange[1] - step;
      } else if (index === 1 && newRange[1] <= newRange[0]) {
        newRange[1] = newRange[0] + step;
      }

      return newRange;
    });
  };
  const handleMouseDown = (e, thumb, index) => {
    const onMouseMove = (e) => handleMove(e.clientX, index);

    const onMouseUp = () => {
      thumb.removeEventListener('mousemove', onMouseMove);
      thumb.removeEventListener('mouseup', onMouseUp);
    };

    thumb.addEventListener('mousemove', onMouseMove);
    thumb.addEventListener('mouseup', onMouseUp);
  };

  const handleTouchStart = (e, thumb, index) => {
    const onTouchMove = (e) => {
      handleMove(e.touches[0].clientX, index);
    };

    const onTouchEnd = () => {
      thumb.removeEventListener('touchmove', onTouchMove);
      thumb.removeEventListener('touchend', onTouchEnd);
    };

    thumb.addEventListener('touchmove', onTouchMove, { passive: true });
    thumb.addEventListener('touchend', onTouchEnd, { passive: true });
  };

  return (
    <div className="slider" ref={sliderRef}>
      <div
        className="slider-track"
        style={{
          left: `${((range[0] - min) / (max - min)) * 100}%`,
          right: `${100 - ((range[1] - min) / (max - min)) * 100}%`,
        }}
      ></div>
      <div
        className="thumb thumb-min"
        ref={thumbMinRef}
        style={{ left: `${((range[0] - min) / (max - min)) * 100}%` }}
        onMouseDown={(e) => handleMouseDown(e, thumbMinRef.current, 0)}
        onTouchStart={(e) => handleTouchStart(e, thumbMinRef.current, 0)}
      ></div>
      <div
        className="thumb thumb-max"
        ref={thumbMaxRef}
        style={{ left: `${((range[1] - min) / (max - min)) * 100}%` }}
        onMouseDown={(e) => handleMouseDown(e, thumbMaxRef.current, 1)}
        onTouchStart={(e) => handleTouchStart(e, thumbMaxRef.current, 1)}
      ></div>
    </div>
  );
};

export default CustomRangeSlider;
