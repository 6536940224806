// ChatList.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import s from '../styles/ChatList.module.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


function ChatList() {
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(''); 
  const { t } = useTranslation(); 

  useEffect(() => {
    const fetchChats = async () => {
      // Проверяем, есть ли JWT-токен в localStorage (для пользователей из Telegram)
      const token = localStorage.getItem("jwtToken"); 
  
      let options = {
        method: 'GET',  // Метод указывается всегда
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      // Если есть токен, добавляем его в заголовки
      if (token) {
        options.headers = {
          "Content-Type": "application/json",
          "Authorization": token,};  // Добавляем JWT в заголовок с префиксом 'Bearer '
      } else {
        // Если пользователя нет в Telegram, включаем поддержку сессий через куки
        options.credentials = 'include';
      }
  
      try {
        const response = await fetch('/api/chats', options);
  
        if (response.status === 401) {
          // Если ошибка 401, перенаправляем на страницу входа
          navigate('/');
          return;
        }
  
        if (!response.ok) {
          // Обрабатываем другие ошибки
          throw new Error('Failed to fetch chats');
        }
  
        const data = await response.json();
        setChats(data);
      } catch (error) {
        console.error('Error fetching chats:', error);
        setErrorMessage('Failed to load chats. Please try again later.'); // Устанавливаем сообщение об ошибке
      }
    };
  
    fetchChats();
  }, [navigate]);
  // Функция для начала чата
  
        
  const startChat = (conversationId) => {
    navigate(`/chat/${conversationId}`);
    };


  return (
    <div>
        <h1 className='all_str_h1'>{t("Chats")}</h1>
        <div className={s.back}>
          <Link to="/search" className={s.btn_prev_2}>
            X
          </Link>                 
      </div>

        {errorMessage && <div className={s.error_message}>{errorMessage}</div>} {/* Отображение сообщения об ошибке */}
        {chats.length > 0 ? (
    <div className={s.chat_list}>
  {chats.map(chat => (
    <div
      key={chat.conversation_id}
      className={s.chat_item}
      onClick={() => startChat(chat.conversation_id)} // Обработчик нажатия на всю строку
      style={{ cursor: 'pointer' }} // Устанавливаем стиль курсора, чтобы было видно, что элемент кликабельный
    >
      <img src={`/api/uploads/${chat.photo}`} alt={`${chat.name}'s icon`} className={s.user_icon} />
      
      <div className={s.chat_content}>
        <div className={s.chat_header}>
          <div className={s.chat_name}>{chat.name}</div>
          <div className={s.last_message}>{chat.last_message}</div>
          
        </div>
        
      </div>
      <span className={`${s.chat_point_read} ${chat.has_unread ? s.unread : ''}`}></span>
      <div className={s.chatdt}>
      <div className={s.chat_time}>
        {new Date(chat.last_message_time).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        })}
      </div>

      <div className={s.chat_date}>
        {new Date(chat.last_message_time).toLocaleDateString([], {
          day: '2-digit',
          month: 'short'
        })}
      </div>
      </div>
    </div>
  ))}
</div>
) : (
  <div className='loading'>{t("No active chats yet")}</div>
)}
</div>

  );
}

export default ChatList;
