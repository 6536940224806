import React from 'react';
import { useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // Импортируем AuthProvider
import { useTranslation } from 'react-i18next';
import Home from './components/Home';
import Logout from './components/Logout';
import Login from './components/Login';
import Register from './components/Register';
import Navbar from './components/Navbar';  // Импорт Navbar
import CreateProfile from './components/CreateProfile';
import Profile from './components/Profile';
import EditProfile from './components/EditProfile';
import SearchProfiles from './components/Search';
import Matches from './components/Matches';
import WhoLike from './components/WhoLike';
import UsersProfile from './components/UsersProfile.jsx';
import UsersProfileLiker from './components/UsersProfileLiker.jsx';
import ChatWindow from './components/ChatWindow.jsx'
import ChatList from './components/ChatList.jsx';
import Menu from './components/Menu.jsx';
import ImageUploader from './components/Foto.jsx';
import EditPhoto from './components/EditPhoto.jsx';
import Training from './components/Training.jsx';
import Subscription from './components/Subscription.jsx';
import TelegramWebApp from './components/TelegramWebApp.jsx';
import OfferPage from './components/offer.jsx';
import PrivacyPolicyPage from './components/PrivacyPolicyPage.jsx';
import Verify from './components/Verify.jsx';

import './components/i18n';
import './styles/all.css';
import './App.css'

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { i18n } = useTranslation();

   // Восстанавливаем язык при загрузке приложения
   useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'ru'; // По умолчанию русский
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  // Прочитать тему из localStorage при первой загрузке приложения
  useEffect(() => {
    const savedTheme = localStorage.getItem('darkMode');
    if (savedTheme === 'true') {
      setDarkMode(true);
      document.documentElement.classList.add('dark_mode');
    }
  }, []);


  // Функция для переключения темы
  const toggleTheme = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode);
      if (newMode) {
        document.documentElement.classList.add('dark_mode');
      } else {
        document.documentElement.classList.remove('dark_mode');
      }
      return newMode;
    });
  };




  return (
    <AuthProvider>
      <div className='body'>
        <Router>
        {/* <Header /> */}
          <Navbar />  {/* Добавление Navbar */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<Register />} />
            <Route path="/CreateProfile" element={<CreateProfile />} />
            <Route path='/login' element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit_profile" element={<EditProfile />} />
            <Route path="/search" element={<SearchProfiles />} />
            <Route path="/matches" element={<Matches />} />
            <Route path="/WhoLike" element={<WhoLike />} />
            <Route path="/UsersProfile/:profileId" element={<UsersProfile />} />
            <Route path="/UsersProfileLiker/:profileId" element={<UsersProfileLiker />} />
            <Route path="/chat/:conversationId" element={<ChatWindow />} />
            <Route path='/ChatList' element={<ChatList />} />
            <Route path='/Menu' element={<Menu />} />
            <Route path='/Foto' element={<ImageUploader />} />
            <Route path='/cropImage' element={<getCroppedImg />} />
            <Route path='/edit_photo' element={<EditPhoto />} />
            <Route path="/Menu" element={<Menu whiteMode={darkMode} toggleTheme={toggleTheme} />} />
            <Route path='/training' element={<Training />} />
            <Route path='/subscription'element={<Subscription/>} />
            <Route path='/telegram' element={<TelegramWebApp/>} />
            <Route path='/offer' element={<OfferPage/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicyPage/>} />
            <Route path='/verify' element={<Verify/>} />
          </Routes>
        </Router>
      </div>
      
  </AuthProvider>
    
  );
}

export default App;
