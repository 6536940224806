import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import '../styles/Matches.css'
import s from '../styles/UsersProfile.module.css'; // Подключение стилей
// import '../styles/Profile.css'; // Подключение стилей
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';

function UsersProfileLiker() {
  const { profileId } = useParams(); // Получаем profileId из параметров URL
  const [profile, setUsersProfile] = useState(null); // Состояние для хранения данных профиля
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [error, setError] = useState(null); // Состояние ошибки
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0); // Текущий индекс фото
  const imageContainerRef = useRef(null); // Реф для контейнера изображений
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [isSwiping, setIsSwiping] = useState(false); // Состояние для отслеживания свайпа

  // Обработчик свайпов для смены фото
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextPhoto(), // Свайп влево - следующее фото
    onSwipedRight: () => handlePreviousPhoto(), // Свайп вправо - предыдущее фото
    onSwipedDown: () => navigate('/matches'), 
    preventScrollOnSwipe: true,
    delta: 10, // Минимальное расстояние для распознавания свайпа
  });

  // Получение профиля пользователя
  useEffect(() => {
    const fetchUsersProfile = async () => {
      try {
        // Проверяем, есть ли данные initData в localStorage (для пользователей из Telegram)
      const token = localStorage.getItem("jwtToken"); 

      let options = {
        method: 'GET',  // Метод указывается всегда
      };

      // Если есть initData (пользователь из Telegram), добавляем его в заголовки
      if (token) {
        options.headers = {
          "Content-Type": "application/json",
          "Authorization": token,  // Передаем JWT в заголовке
        };
      } else {
        // Если пользователь не из Telegram, включаем поддержку сессий через куки
        options.credentials = 'include';
      }

      const response = await fetch(`/api/users_profile?profile_id=${profileId}`, options);

        // const response = await fetch(`/api/users_profile?profile_id=${profileId}`, {
        //   method: 'GET',
        //   credentials: 'include', // Включаем куки для сессии
        // });

        if (response.ok) {
          const data = await response.json();
          setUsersProfile({
            ...data.users_profile,
            photos: data.users_profile.photos ? data.users_profile.photos : [], // Если нет фото, то пустой массив
          });
        } else {
          if (response.status === 404) {
            navigate('/matches'); // Перенаправляем, если профиль не найден
          } else {
            console.error('Error fetching profile');
            setError('Error fetching profile');
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Error fetching profile');
      } finally {
        setLoading(false); // Завершаем загрузку
      }
    };

    fetchUsersProfile();
  }, [navigate, profileId]);

  const handleNextPhoto = () => {
    if (profile && profile.photos.length > currentPhotoIndex + 1) {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    }
  };

  const handlePreviousPhoto = () => {
    if (profile && currentPhotoIndex > 0) {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    }
  };

  // Функция для начала чата
  const handleStartChat = async () => {
    try {
      // Проверяем, есть ли данные initData в localStorage (для пользователей из Telegram)
      const token = localStorage.getItem("jwtToken"); 
      console.log('токен:',token)
      let options = {
        method: 'POST',  // Метод указывается всегда
        headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ profile_id: profileId }),  // Отправляем ID профиля через тело запроса
      };

      // Если есть initData (пользователь из Telegram), добавляем его в заголовки
      if (token) {
        options.headers = {
          'Content-Type': 'application/json',
          "Authorization": token,  // Передаем JWT в заголовке
        };
      } else {
        // Если пользователь не из Telegram, включаем поддержку сессий через куки
        options.credentials = 'include';
      }

      const response = await fetch('/api/conversations/start', options);

      if (response.ok) {
        // Внутри функции handleStartChat

        const data = await response.json();
        console.log('Response data:', data); // Добавьте это
        const conversationId = data.conversation_id;
        console.log('Conversation ID:', conversationId); // И это
        navigate(`/chat/${conversationId}`);
    } else {
        console.error('Failed to start chat');
        setError('Failed to start chat');
    }
  
    } catch (error) {
      console.error('Error starting chat:', error);
      setError('Error starting chat');
    }
  };


  const handleUnmatch = async () => {
     // Включаем индикатор загрузки
    setError(null); // Сбрасываем ошибки

    try {
      // Проверяем, есть ли данные initData в localStorage (для пользователей из Telegram)
      const token = localStorage.getItem("jwtToken"); 
      console.log('токен:',token)
      let options = {
        method: 'POST',  // Метод указывается всегда
        headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ profile_id: profileId }),  // Отправляем ID профиля через тело запроса
      };

      // Если есть initData (пользователь из Telegram), добавляем его в заголовки
      if (token) {
        options.headers = {
          'Content-Type': 'application/json',
          "Authorization": token,  // Передаем JWT в заголовке
        };
      } else {
        // Если пользователь не из Telegram, включаем поддержку сессий через куки
        options.credentials = 'include';
      }

      const response = await fetch('/api/unmatch', options);

      const data = await response.json();

      if (response.ok) {
        navigate('/matches');
      } else {
        setError(data.error || 'Failed to unmatch.');
      }
    } catch (err) {
      setError('An error occurred while unmatching.');
    } finally {
      
    }
  };



  // Функция для перемещения контейнера изображений в нужное положение
  useEffect(() => {
    if (imageContainerRef.current) {
      const width = imageContainerRef.current.offsetWidth;
      imageContainerRef.current.style.transform = `translateX(-${currentPhotoIndex * width}px)`;
      // setIsSwiping(false); // Сбрасываем состояние свайпа
    }
  }, [currentPhotoIndex]);

  if (loading) {
    return <div>{t("Loading")}...</div>;
  }

  if (!profile) {
    return <div>Profile not found</div>;
  }

  return (
    <div>
    <h1 className='all_str_h1'>{t("Matches")}</h1>
    <div className={s.profile_container}>
      <div className={s.profile}>
        <div {...handlers} className={s.imgus}>
          {/* Проверка, что есть хотя бы одно фото */}
          {profile.photos.length > 0 ? (
            <div className={s.image_slider} ref={imageContainerRef}>
              {profile.photos.map((photo, index) => (
                <img
                  key={index}
                  src={`/api/uploads/${photo}`}
                  alt="Profile"
                  className={s.styled_imageus}
                />
              ))}
            </div>
            
          
          ) : (
            <p>No photos available</p>
          )}
          {/* Значки для перелистывания фото, отображаем только если фото больше одного */}
        {profile.photos.length > 1 && (
          <>
            <button className={s.prev} onClick={handlePreviousPhoto} disabled={currentPhotoIndex === 0}>
              {'<'}
            </button>
            <button className={s.next} onClick={handleNextPhoto} disabled={currentPhotoIndex === profile.photos.length - 1}>
              {'>'}
            </button>
          </>
        )}
      </div>
        {/* Отображаем индикаторы, если есть фото */}
        {profile.photos.length > 1 && (
          <div className="indicators">
            {profile.photos.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentPhotoIndex ? 'active_dot' : ''}`}
              ></span>
            ))}
          </div>
        )}
        <div className={s.profile_bio}>
          <div className={s.name_age}>
            <p>{profile.name}</p>
            <p>{profile.age}</p>
          </div>
          <p>City: {profile.city}</p>
          <p>{t('gender.' + profile.gender)}</p>
        </div>
      </div>
      <div className={s.bio_card}>
        <p>{profile.bio}</p> {/* Bio выводится в отдельной карточке */}
      </div>
      <div className={s.btn_profile}>
        {/* Добавляем кнопку Start Chat */}
        <button onClick={handleUnmatch}>Unmatch</button>
        <button onClick={handleStartChat}>Start Chat</button>

      </div>
      {error && <p>{error}</p>} {/* Отображаем ошибку, если есть */}
    </div>
    </div>
    
  );
}

export default UsersProfileLiker;
