import React, { useState, useEffect } from "react";
import s from '../styles/GenderSelectCard.module.css'; // если захочешь стилизовать отдельно
import { useTranslation } from 'react-i18next';

const GenderSelectCard = () => {
  const [isOpen, setIsOpen] = useState(false); // состояние для управления открытием поля
  const [selectedGender, setSelectedGender] = useState("Any"); // начальное состояние выбора пола
  const { t } = useTranslation(); 
  const genders = ["Any", "Male", "Female"]; // список возможных вариантов гендера

  // Чтение выбранного пола из localStorage при загрузке компонента
  useEffect(() => {
    const savedGender = localStorage.getItem("selectedGender");
    if (savedGender) {
      setSelectedGender(savedGender);
    }
  }, []);

  // Сохранение выбранного пола в localStorage при изменении состояния
  useEffect(() => {
    localStorage.setItem("selectedGender", selectedGender);
  }, [selectedGender]);


  // Функция для обработки изменения выбора
  const handleGenderChange = (gender) => {
    setSelectedGender(gender);
    setIsOpen(false); // закрываем поле после выбора
  };

  return (
    <div className={s.gender_select_card} onClick={() => setIsOpen(!isOpen)}>
      <div className={s.selected_gender}>
        <strong>{t("Gender")}: </strong>{t(`gender_menu.${selectedGender}`)}
      </div>
      {isOpen && (
        <div className={s.gender_options}>
          {genders.map((gender) => (
            <div
              key={gender}
              className={`${s.gender_option} ${gender === selectedGender ? s.selected : ""}`}
              onClick={() => handleGenderChange(gender)}
            >
              {t(`gender_menu.${gender}`)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GenderSelectCard;
