import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'; // Подключаем i18n
import s from '../styles/language.module.css'; // если захочешь стилизовать отдельно

const LanguageSelectCard = () => {
  const { i18n } = useTranslation(); // Используем i18next для смены языка
  const [isOpen, setIsOpen] = useState(false); // состояние для управления открытием меню
  const [selectedLanguage, setSelectedLanguage] = useState("ru"); // Начальный язык

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'ru', label: 'Русский' }
  ]; // Список возможных языков

  // Чтение выбранного языка из localStorage при загрузке компонента
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || 'ru'; // По умолчанию русский
    setSelectedLanguage(savedLanguage); // Устанавливаем язык в состоянии
    i18n.changeLanguage(savedLanguage); // Применяем язык через i18next
  }, [i18n]);

  // Сохранение выбранного языка в localStorage и применение его через i18n
  useEffect(() => {
    localStorage.setItem("language", selectedLanguage); // Сохраняем выбор пользователя
    i18n.changeLanguage(selectedLanguage); // Применяем язык через i18next
  }, [selectedLanguage, i18n]);

  // Функция для обработки изменения выбора языка
  const handleLanguageChange = (code) => {
    setSelectedLanguage(code); // Меняем выбранный язык
    setIsOpen(false); // Закрываем меню после выбора
  };

  return (
    <div className={s.language_select_card} onClick={() => setIsOpen(!isOpen)}>
      <div className={s.selected_language}>
        <strong>Language: </strong>{languages.find(lang => lang.code === selectedLanguage)?.label}
      </div>
      {isOpen && (
        <div className={s.language_options}>
          {languages.map((language) => (
            <div
              key={language.code}
              className={`${s.language_option} ${language.code === selectedLanguage ? s.selected : ""}`}
              onClick={() => handleLanguageChange(language.code)}
            >
              {language.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelectCard;
