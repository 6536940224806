// import React, { useState, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import s from '../styles/Verify.module.css'
// import { AuthContext } from '../AuthContext';
// import { useTranslation } from 'react-i18next';

// const Verify = () => {
//   const [verificationCode, setVerificationCode] = useState('');
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();
//   const { fetchCurrentUser } = useContext(AuthContext); 
//   const { t } = useTranslation();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch('/api/verify', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ code: verificationCode }),
//         credentials: 'include',  // Передача сессионных куки для авторизованных пользователей
//       });

//       const data = await response.json();

//       if (data.success) {
//         await fetchCurrentUser();
//         // Успешная верификация, перенаправляем пользователя на защищённую страницу
//         navigate('/CreateProfile');  // Замените на нужный маршрут
//       } else {
//         setError('Invalid verification code. Please try again.');
//       }
//     } catch (err) {
//       setError('An error occurred while verifying. Please try again later.');
//     }
//   };

//   return (
//     <div className={s.verify_container}>
//       <h2>{t('Verify Your Account')}</h2>
//       <form onSubmit={handleSubmit}>
//         <label>
//           {t('Enter Verification Code:')}
//           <input
//             type="text"
//             value={verificationCode}
//             onChange={(e) => setVerificationCode(e.target.value)}
//             required
//           />
//         </label>
//         <button type="submit">{t('Verify')}</button>
//       </form>
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//     </div>
//   );
// };

// export default Verify;
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import s from '../styles/Verify.module.css';
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';

const Verify = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(null);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(60); // Таймер для повторной отправки
  const navigate = useNavigate();
  const { fetchCurrentUser } = useContext(AuthContext); 
  const { t } = useTranslation();

  // Обработчик отправки кода верификации
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: verificationCode }),
        credentials: 'include',
      });

      const data = await response.json();

      if (data.success) {
        await fetchCurrentUser();
        navigate('/CreateProfile'); // Замените на нужный маршрут
      } else {
        setError('Invalid verification code. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while verifying. Please try again later.');
    }
  };

  // Обработчик повторной отправки кода
  const handleResendCode = async () => {
    try {
      setIsResendDisabled(true); // Блокируем кнопку на 60 секунд
      setTimer(60); // Сбрасываем таймер

      const response = await fetch('/api/send_verification_code', {
        method: 'GET', // Используем GET-запрос
        credentials: 'include', // user_id будет получен из сессии
      });

      if (!response.ok) {
        setError('Failed to resend verification code. Please try again later.');
      }
    } catch (err) {
      setError('An error occurred while resending the code. Please try again later.');
    }
  };

  // Таймер для разблокировки кнопки повторной отправки кода
  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 60;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isResendDisabled]);

  return (
    <div className={s.verify_container}>
      <h2>{t('Verify Your Account')}</h2>
      <form onSubmit={handleSubmit}>
        <label>
          {t('Enter Verification Code:')}
          <input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
          />
        </label>
        <button type="submit" className={s.verify_button}>{t('Verify')}</button>
        <p>{t("If you don't receive the code, try sending again.")}</p>
        <button type="button" onClick={handleResendCode} disabled={isResendDisabled} className={s.resend_button2}>
        {isResendDisabled ? `${t('Resend in')} ${timer}s` : t('Resend Code')}
      </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      
    </div>
  );
};

export default Verify;
