import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import s from '../styles/Training.module.css';
import { useTranslation } from 'react-i18next';

function Training() {

    const [profile, setProfile] = useState(null);  // Текущее состояние профиля
  const [loading, setLoading] = useState(true);  // Состояние загрузки
  const [error, setError] = useState(null);      // Ошибки
  const [isProfileExpanded, setIsProfileExpanded] = useState(false); // Состояние для раскрытия профиля
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0); // Индекс текущего фото
  const navigate = useNavigate();
  const { t } = useTranslation();


  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [scale, setScale] = useState(0);
  const [direction, setDirection] = useState(null);

  const [step, setStep] = useState(1);
  const totalSteps = 6; // Всего шагов регистрации

    // Функция получения профиля текущего пользователя
    useEffect(() => {
    const fetchTraining = async () => {
      try {
        // Проверяем, есть ли данные initData в localStorage (для пользователей из Telegram)
        const token = localStorage.getItem("jwtToken"); 

        let options = {
          method: 'GET',  // Метод указывается всегда
        };

        // Если есть initData (пользователь из Telegram), добавляем его в заголовки
        if (token) {
          options.headers = {
            "Content-Type": "application/json",
            "Authorization": token,  // Передаем JWT в заголовке
          };
        } else {
          // Если пользователь не из Telegram, включаем поддержку сессий через куки
          options.credentials = 'include';
        }

        const response = await fetch('/api/training', options);

        // const response = await fetch('/api/training', {
        //   method: 'GET',
        //   credentials: 'include', // Включаем куки для сессии
        // });

        if (response.ok) {
          const data = await response.json();
          setProfile(data);
        } else {
          if (response.status === 404) {
            navigate('/');  // Перенаправляем, если профиль не найден
          } else {
            navigate('/');
            console.error('Error fetching profile');
            setError('Error fetching profile');
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Error fetching profile');
      } finally {
        setLoading(false);  // Завершаем загрузку
      }
    };

    fetchTraining();
  }, [navigate]);

  // Переход на следующий шаг
  const nextStep = () => {
    if (step < totalSteps) {
      setStep(step + 1);
    }
  };

  


  // Обработка начала касания
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
    // setIsTouching(true); // Показываем картинку при касании
    setScale(0); // Сбрасываем масштаб до 0 при начале касания
  };

  const handleTouchMove = (e) => {
    const currentTouch = e.targetTouches[0].clientX;
    setTouchEnd(currentTouch);
    
  
    if (touchStart !== null) {
      const delta = currentTouch - touchStart;
  
      if (delta > 15) {
        setDirection('right');
        setScale(0.4 + delta / 200); // Увеличиваем картинку динамически
      } else if (delta < -15) {
        setDirection('left');
        setScale(0.4 + Math.abs(delta) / 200); // Увеличиваем картинку динамически
      }
      // Если пользователь вернулся близко к исходной точке касания
    else {
      setScale(0); // Сбрасываем картинку до исходного состояния
    }
    }
  };

  const handleTouchEnd = () => {
    if (touchStart && touchEnd) {
      const delta = touchEnd - touchStart;
  
      if (delta > 50) {
        // Если движение вправо
        setDirection('right');
        setScale((prevScale) => Math.min(prevScale + 0.2, 3)); // Увеличиваем картинку
  
        // setTimeout(() => {
        //   handleLike(); // Действие лайка
        //   setScale(1); // Сбрасываем масштаб
        // }, 300); // Задержка для плавного отображения
      } else if (delta < -50) {
        // Если движение влево
        setDirection('left');
        setScale((prevScale) => Math.min(prevScale + 0.2, 3)); // Увеличиваем картинку
  
        // setTimeout(() => {
        //   handleDislike(); // Действие дизлайка
        //   setScale(1); // Сбрасываем масштаб
          
        // }, 300); // Задержка для плавного отображения
      }
      // Если свайп был незначительным
    else {
      setScale(0); // Сброс масштаба
    }
    }
  
    // Сбрасываем значения для следующего жеста
    setTouchStart(null);
    setTouchEnd(null);
    setScale(0);
    // setIsTouching(false); // Скрываем картинку после завершения касания
  };
  
  // Функции для смены фото
  const handleNextPhoto = () => {
    if (profile && profile.photos.length > 0) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === profile.photos.length - 1 ? 0 : prevIndex + 1
      );
      
    }
  };

  const handlePreviousPhoto = () => {
    if (profile && profile.photos.length > 0) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === 0 ? profile.photos.length - 1 : prevIndex - 1
      );
    }
  };

  // Обработчик свайпов
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isProfileExpanded && step===2) {
        nextStep();
        handleNextPhoto();
          // Свайп влево - отправить дизлайк, если профиль не раскрыт
      } else {
      // Свайп влево переключает фото, если профиль раскрыт
      }
    },
    onSwipedRight: () => {
      if (!isProfileExpanded && step===1) {
        nextStep(); // Свайп вправо - отправить лайк, если профиль не раскрыт
        handleNextPhoto();
      } else {
          // Свайп вправо переключает фото, если профиль раскрыт
      }
    },
    onSwipedUp: () => {
      if (!isProfileExpanded && step===3) {
        setIsProfileExpanded(true);  // Свайп вверх - раскрыть профиль только если он еще не раскрыт
        nextStep(); 
    }
    },  // Свайп вверх - раскрыть профиль
    onSwipedDown: () => {if (isProfileExpanded && step===4) {setIsProfileExpanded(false);
    nextStep(); // Свайп вправо - отправить лайк, если профиль не раскрыт
    handleNextPhoto();
    }
    },  // Свайп вниз - вернуть состояние для лайков/дизлайков
    preventScrollOnSwipe: false,// !isProfileExpanded, 
    delta: 20  // Минимальное расстояние для распознавания свайпа
  });

  if (loading) {
    return <div className='loading'>{t("Loading")}...</div>;
  }
  

return (
<div>
    <div className={s.head_link}>
    {/* <h1 className='all_str_h1'>Training</h1> */}
    <div></div>
     <Link className={s.skip} to="/search">{t("skip")} &gt;</Link> 
    </div>
    <div>
    <div {...handlers} className={s.profile_container}>
      {profile ? (
        <div  className={s.profile}>
          <div className={s.img}>
          {!isProfileExpanded && (
            <>
            {step === 1 && (
            <div className={s.training_area_start}>{/*Let's start training. */}
                <p className={s.start_text}>
                {t("Let's start training")}.
                </p>
                <img
                      src={`${process.env.PUBLIC_URL}/right_1.svg`}
                      alt="Left swipe"
                      className={s.swipe_icon}
                    />
                <p className={s.training_area_text}>
                {t("Swipe right")}.
                </p>
            </div>
            )}
            {step === 2 && (
            <div className={s.training_area}>
                <img
                      src={`${process.env.PUBLIC_URL}/left_1.svg`}
                      alt="Left swipe"
                      className={s.swipe_icon}
                    />
                <p className={s.training_area_text}>
                {t("Swipe left")}.
                </p>
            </div>
            )}
            {step === 3 && (
            <div className={s.training_area}>
                <img
                      src={`${process.env.PUBLIC_URL}/up_1.svg`}
                      alt="Left swipe"
                      className={s.swipe_icon}
                    />
                <p className={s.training_area_text}>
                
                    {t("Swipe up")}.
                </p>
            </div>
            )}
            {step === 5 && (
            <div className={s.training_area_push}>
                <div className={s.push}>
                <img
                      src={`${process.env.PUBLIC_URL}/left_push_1.svg`}
                      alt="Left swipe"
                      className={s.swipe_icon}
                    />
                <img
                      src={`${process.env.PUBLIC_URL}/right_push_1.svg`}
                      alt="Left swipe"
                      className={s.swipe_icon}
                    />
                </div>
                <p className={s.training_area_text_push}>
                {t("Click on the buttons")}.
                </p>
            </div>
            )}
             {step === 6 && (
            <div className={s.training_area_finish}>
                <p className={s.training_area_text_finish}>
                {t("completed")}
                </p>
                <div className={s.finish}>
                <Link className={s.finish_link} to="/search">START</Link>
                <img
                      src={`${process.env.PUBLIC_URL}/push_1.svg`}
                      alt="Left swipe"
                      className={s.swipe_icon}
                    />
                </div>
               
            </div>
            )}
            <img src={`/api/uploads/${profile.photos[currentPhotoIndex]}`} alt="Profile" className={s.styled_image} />
            </>
            )}
            {!isProfileExpanded && step >= 4 &&  profile.photos.length > 1 && (
              <>
                {/* <button className={s.prev} onClick={handlePreviousPhoto}>{'<'}</button> */}
                <button className={s.prev} onClick={() => {
                        handlePreviousPhoto(); // Смена фото назад
                        setStep(6); // Установка шага
                    }}>
                    {'<'}
                    </button>
                    <button className={s.next} onClick={() => {
                        handleNextPhoto(); // Смена фото назад
                        setStep(6); // Установка шага
                    }}>
                    {'>'}
                    </button>
              </>
            )}
            {!isProfileExpanded && (
              <>
                <div 
                  className={s.swipe_carding}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  
                >
                  {direction === 'left' && step < 4&&  (
                    <img
                      src={`${process.env.PUBLIC_URL}/dislike.svg`}
                      alt="Left swipe"
                      className={s.swipe_image}
                      style={{ transform: `scale(${scale})` }}
                    />
                  )}
                  {direction === 'right' && step < 4&& (
                    <img
                      src={`${process.env.PUBLIC_URL}/like3.svg`}
                      alt="Right swipe"
                      className={s.swipe_image}
                      style={{ transform: `scale(${scale})` }}
                    />
                  )}
                </div>
                            </>
            )}
          </div>

          {/* Индикаторы фото отображаются только в режиме просмотра профиля */}
          {!isProfileExpanded && step >= 4 && profile.photos.length > 1 && (
            <div className="indicators">
              {profile.photos.map((photo, index) => (
                <span
                  key={index}
                  className={`dot ${index === currentPhotoIndex ? 'active_dot' : ''}`}
                ></span>
              ))}
            </div>
          )}

          {/* Основная информация о профиле */}
          {step > 3 && (
            <div className={s.profile_bio}>
                        <div className={s.name_age}>
                        <p>{profile.name}</p>
                        <p>{profile.age}</p>
                        </div>
                        <p>{t("City")}: {profile.city}</p>
                        <p>{t("Gender_cr")}: {profile.gender}</p>
                    </div>
          )}
          
            <div className={s.full_bio}>
                    {/* Отображаем полные данные профиля при свайпе вверх */}
                      {isProfileExpanded && (
                        <div className={s.bio_card}>
                            {step === 4 && (
                            <div className={s.training_area_expanded}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/down_1.svg`}
                                    alt="Left swipe"
                                    className={s.swipe_icon_expanded}
                                    />
                                <p className={s.training_area_text_expanded}>
                                    {t("Swipe down")}
                                </p>
                            </div>
                            )}
                          <p>{profile.bio}</p>
                        </div>
                      )}
                </div>
          
        </div>
      ) : (
        
          <div></div>  // Сообщение, если профили закончились
        
        )}
      
    </div>
    </div>
    {error && <p>{error}</p>}
    </div>
);
}

export default Training;
