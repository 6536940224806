import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Дополнительная функция для получения обрезанного изображения
import s from '../styles/Foto.module.css';
import { useTranslation } from 'react-i18next';


function ImageUploader({ setPhotoBlob }) {
  const { t } = useTranslation(); 
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null); // Для хранения обрезанного изображения
  // const [croppedBlob, setCroppedBlob] = useState(null); // Для хранения blob


  // Обработчик загрузки файла
  const onFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSrc(reader.result);
    };
  };

  // Получение области обрезки
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  // Подтверждение обрезки и сохранение
  const onCropConfirm = async () => {
    try {
      const { blob, fileUrl } = await getCroppedImg(imageSrc, croppedArea);
      // setCroppedBlob(blob); // Сохраняем blob для отправки на сервер
      setCroppedImage(fileUrl); // Сохраняем URL для отображения обрезанного изображения
      setPhotoBlob(blob); // Передаём обрезанный blob в родительский компонент
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {!croppedImage && (
        <>
          <input type="file" onChange={onFileChange} />
        </>
      )}
      {imageSrc && !croppedImage && (
        <>
          <div className={s.crop_container}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={3 / 4} // Задаём соотношение сторон
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className={s.btn_profile}>
            <button type="button" onClick={onCropConfirm}>{t("Confirm Crop")}</button>
          </div>
        </>
      )}
      {croppedImage && (
        <div className={s.cropped}>
          <h3>Preview Cropped Image</h3>
          <img src={croppedImage} alt="Cropped" />
        </div>
      )}
    </div>
  );
}

export default ImageUploader;
